import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    deleteAwvFormResponse,
    downloadAwvFormDocumentResponse,
    fetchMemberProfileDetail,
    generateAwvSummaryLocalStorageKey,
    getAwvAttestationTemplate,
    getAwvElectronicForm,
    getMemberAwvSummaries,
    getMemberNpiAwvSummaries,
    processNewOrUpdatedAwvSummary,
    renderAwvElectronicFormPdf,
    saveAwvFormDocumentResponse,
    submitSavedAwvFormResponse
} from 'redux/slices/memberProfile'
import { dispatch, useAppSelector } from 'redux/store'
import FileDownloader from './FileDownloader'
import {
    StyledAWVContainer,
    StyledAWVInfoActionsContainer,
    StyledAWVInfoContainer,
    StyledAWVInfoInputContainer,
    StyledAWVInfoInputWrap,
    StyledCalendarIcon,
    StyledDropdownContainer,
    StyledActionButton,
    StyledAWVInfoInputWithRightButtonContainer,
    StyledAWVInstructionWrap,
    StyledAWVTitleDetailContainer,
    StyledAWVLongInstructionWrap,
    StyledAWVFormTitle,
    StyledDocumentsModalListContainer,
    StyledSmallDetailsButton,
    StyledMediumDetailsButton,
    StyledNoLeftMarginSmallDetailsButton,
    StyledDownloadButtonWrap,
    StyledDetailsButton,
    StyledStatusBox
} from './index.style'
import smallCalendar from 'assets/smallCalendar.svg'
import DropDownList, { IOption } from 'components/DropDownList'
import { calendarDropDownListStyleParams } from 'components/TaxIdProviderInfo/index.style'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import moment from 'moment'
import Spinner from 'components/Spinner'
import { getAwvSummaryLabels } from './index.util'
import { UI_COLORS } from 'theme/constants'
import { appPath } from 'utilities/appPath'
import ConfirmationBox from 'components/ConfirmationBox'
import AWVHistoryModal from './AwvHistoryModal'
import IconButton from '@mui/material/IconButton'
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import { getStatusIcon, openInNew, downloadIcon, uploadIcon } from './icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { hideMainWindowScrollbars } from 'utilities'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'
import { AWVType } from './enum'
import UploadFileModal from 'components/UploadFileModal'
import MessageBox from 'components/MessageBox'
import SupportingDocumentsTable from './SupportingDocumentsTable'
import {
    DocumentsState,
    deleteAwvSupportingDocument,
    deleteAwvSupportingDocuments,
    downloadAwvSupportingDocument,
    getAwvKey,
    getAwvSupportingDocuments,
    uploadAwvSupportingDocument
} from 'redux/slices/documents'
import {
    IAwvFormElectronicResponseSummary,
    IAwvSummary,
    IAwvSupportingDocument
} from 'pages/hcp/users/create/index.models'
import AWVFeedback from './Feedback'
import { FeedbackModalDialog } from '../PointsInCare/ReviewTable/FeedbackDialog'
import {
    FeedbackState,
    addAwvFeedback,
    getAwvFeedback,
    hasNewAwvFeedback,
    markAwvFeedbackRead
} from 'redux/slices/feedback'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import isYearOpenForAwv from 'utilities/isYearOpenForAwv'
import { IProviderNpi, Pulse8State, fetchProviderNpis } from 'redux/slices/pulse8'
import NonPCPSubmissionWarning from './NonPCPSubmissionWarning'
import UnvalidatedAwvSubmissionDialog from 'components/UnvalidatedAwvSubmissionDialog'
import { setAwvValidationState } from 'redux/slices/ui'
import AttestationModal from './AttestationModal'
import { unwrapResult } from '@reduxjs/toolkit'
import PCPCredentialModal from './PCPCredentialModal'
import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'

const adminCalendarDropDownListStyleParams = {
    ...calendarDropDownListStyleParams,
    width: 280
}

interface IYearProvider {
    year: number
    npi: string
    providerName: string
}

interface IAWVProps {
    isLoading: (isFormLoading: boolean) => void
}

const AWV: FC<IAWVProps> = ({ isLoading }) => {
    const [memberId, npi, urlYear] = useMemberInfoFromUrl()

    const currentUser = useCurrentUser()

    const isAdmin = [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Quality].includes(currentUser?.primaryRole)

    const currentYear = moment().year()
    const currentMonth = moment().month() + 1

    const { awvSummaries, memberProfileDetails } = useSelector((state: { memberProfile: any }) => state.memberProfile)

    const [memberProfileDetailsLoading, setMemberProfileDetailsLoading] = useState(false)

    const [yearProviders, setYearProviders] = useState<IYearProvider[]>([])
    const [yearProviderOptions, setYearProviderOptions] = useState<IOption[]>(null)
    const [selectedYearProviderIndex, setSelectedYearProviderIndex] = useState(-1)

    const [isBadData, setIsBadData] = useState(false)
    const [isAwvSummariesLoaded, setIsAwvSummariesLoaded] = useState(false)
    const [initialContentLoaded, setInitialContentLoaded] = useState(false)

    const [downloading, setDownloading] = useState(false)
    const [renderingAwvElectronicFormPdf, setRenderingAwvElectronicFormPdf] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [fileToUpload, setFileToUpload] = useState<any>(null)
    const [uploadResponseModalShown, setUploadResponseModalShown] = useState(false)
    const [responseSavedMessageBoxShown, setResponseSavedMessageBoxShown] = useState(false)
    const [isAttesting, setIsAttesting] = useState(false)
    const [isElectronicAwvSubmitByNonPCP, setElectronicAwvSubmitByNonPCP] = useState(false)
    const [unvalidatedFormDialogShown, setUnvalidatedFormDialogShown] = useState(false)
    const [confirmEditShown, setConfirmEditShown] = useState(false)
    const [confirmEditMessage, setConfirmEditMessage] = useState('')
    const [confirmDeleteShown, setConfirmDeleteShown] = useState(false)
    const [confirmUploadShown, setConfirmUploadShown] = useState(false)
    const [confirmUploadMessage, setConfirmUploadMessage] = useState('')
    const [fileTooLargeMessageBoxShown, setFileTooLargeMessageBoxShown] = useState(false)
    const [confirmSupportingDocumentDeletionShown, setConfirmSupportingDocumentDeletionShown] = useState(false)
    const [showAWVHistory, setShowAWVHistory] = useState(false)
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)

    const { awvSupportingDocuments } = useSelector((state: { documents: DocumentsState }) => state.documents)
    const [supportingDocumentsLoading, setSupportingDocumentsLoading] = useState(false)
    const [uploadSupportingDocumentModalShown, setUploadSupportingDocumentModalShown] = useState(false)
    const [supportingDocumentUploading, setSupportingDocumentUploading] = useState(false)
    const [supportingDocumentDownloading, setSupportingDocumentDownloadingOrDeleting] = useState(false)
    const [supportingDocumentToDelete, setSupportingDocumentToDelete] = useState<IAwvSupportingDocument>(null)

    const selectedYearProvider = selectedYearProviderIndex >= 0 ? yearProviders?.[selectedYearProviderIndex] : null
    const selectedAwvSummary = awvSummaries.awvSummaries?.find(
        (x: any) => x.year === selectedYearProvider?.year && x.providerNpi === selectedYearProvider?.npi
    ) as IAwvSummary

    const { effectiveDateLabel, effectiveDateValue, typeLabel } = getAwvSummaryLabels(selectedAwvSummary)

    const selectedYearOpen = isYearOpenForAwv(selectedYearProvider?.year)

    const canSaveOrSubmit =
        !isAdmin &&
        selectedYearOpen &&
        (!selectedAwvSummary ||
            selectedAwvSummary.status === 'Scheduled' ||
            selectedAwvSummary.status === 'Saved' ||
            selectedAwvSummary.status === 'Submitted' ||
            selectedAwvSummary.status === 'Rejected')

    const canSubmitSaved = !isAdmin && selectedYearOpen && selectedAwvSummary?.status === 'Saved'

    const canDelete = canSaveOrSubmit && selectedAwvSummary?.status !== 'Rejected'

    useEffect(() => {
        if (
            !memberProfileDetailsLoading &&
            (!memberProfileDetails ||
                memberProfileDetails.memberId !== memberId ||
                (npi && memberProfileDetails.npi !== npi))
        ) {
            setMemberProfileDetailsLoading(true)
            dispatch(fetchMemberProfileDetail({ memberId, npi: npi ? npi : undefined }))
                .unwrap()
                .then(() => {
                    setMemberProfileDetailsLoading(false)
                })
                .catch(() => {
                    setIsBadData(true)
                })
        }
    }, [memberProfileDetails, memberProfileDetailsLoading, memberId, npi])

    useEffect(() => {
        if (!memberProfileDetailsLoading && initialContentLoaded) {
            isLoading(false)
        }
    }, [memberProfileDetailsLoading, initialContentLoaded])

    useEffect(() => {
        if (isAwvSummariesLoaded) {
            const addYear = (yearProviders: IYearProvider[], year: number) => {
                if (!yearProviders.find((x) => x.year === year && x.npi === npi)) {
                    yearProviders.push({ year, npi, providerName: '' })
                }
            }
            const yearProviders: IYearProvider[] =
                awvSummaries.awvSummaries.length > 0
                    ? awvSummaries.awvSummaries.map((x: IAwvSummary) => ({
                          year: x.year,
                          npi: x.providerNpi,
                          providerName: `${x.providerLastName}, ${x.providerFirstName}`
                      }))
                    : []

            if (!isAdmin && npi) {
                addYear(yearProviders, currentYear)
                const previousYear = currentYear - 1
                if (isYearOpenForAwv(previousYear)) {
                    addYear(yearProviders, previousYear)
                }
            }
            setYearProviders(
                yearProviders.sort((x, y) => {
                    if (x.year === y.year) {
                        return x.providerName.localeCompare(y.providerName)
                    } else {
                        return y.year - x.year
                    }
                })
            )
            setInitialContentLoaded(true)
        }
    }, [isAwvSummariesLoaded, awvSummaries, isAdmin, npi, currentYear, currentMonth, urlYear])

    useEffect(() => {
        if (yearProviders) {
            setYearProviderOptions(
                yearProviders.map((x, index) => ({
                    label: isAdmin ? `${x.year} - ${x.providerName}` : x.year.toString(),
                    value: index
                }))
            )
        }
    }, [yearProviders, isAdmin])

    useEffect(() => {
        let selectedYear = currentYear
        if (urlYear > 0 && yearProviders.findIndex((x) => x.year === urlYear) >= 0) {
            selectedYear = urlYear
        }
        if (yearProviders?.length > 0 && yearProviderOptions?.length > 0 && selectedYearProviderIndex === -1) {
            setSelectedYearProviderIndex(
                Math.max(
                    yearProviders.findIndex((x) => x.year === selectedYear),
                    0
                )
            )
        }
    }, [yearProviders, yearProviderOptions, selectedYearProviderIndex, currentYear, urlYear])

    useEffect(() => {
        if (!isAwvSummariesLoaded) {
            if (isAdmin) {
                dispatch(getMemberAwvSummaries({ memberId })).then(() => {
                    setIsAwvSummariesLoaded(true)
                })
            } else if (npi) {
                dispatch(getMemberNpiAwvSummaries({ memberId, npi: npi })).then(() => {
                    setIsAwvSummariesLoaded(true)
                })
            }
        }
    }, [isAwvSummariesLoaded, isAdmin, memberId, npi])

    const selectedAwvSummaryType = selectedAwvSummary?.$type
    const selectedAwvSummaryStatus = selectedAwvSummary?.status
    const selectedAwvSummaryProviderNpi = selectedAwvSummary?.providerNpi
    const selectedAwvSummaryYear = selectedAwvSummary?.year

    useEffect(() => {
        if (
            initialContentLoaded &&
            (selectedAwvSummaryType === AWVType.Document || selectedAwvSummaryType === AWVType.Electronic) &&
            ['Saved', 'Submitted', 'InReview', 'Approved', 'Denied', 'Rejected'].includes(selectedAwvSummaryStatus)
        ) {
            setSupportingDocumentsLoading(true)
            dispatch(
                getAwvSupportingDocuments({
                    memberId,
                    npi: selectedAwvSummaryProviderNpi,
                    year: selectedAwvSummaryYear
                })
            ).then(() => {
                setSupportingDocumentsLoading(false)
            })
        }
    }, [
        initialContentLoaded,
        selectedAwvSummaryType,
        selectedAwvSummaryStatus,
        selectedAwvSummaryProviderNpi,
        selectedAwvSummaryYear,
        memberId
    ])

    useEffect(() => {
        if (fileToUpload && uploading && npi && selectedYearProvider) {
            dispatch(
                saveAwvFormDocumentResponse({ memberId, npi: npi, year: selectedYearProvider.year, file: fileToUpload })
            ).then(() => {
                setFileToUpload(null)
                setUploading(false)
            })
        }
    }, [fileToUpload, uploading, npi, selectedYearProvider, memberId])

    useEffect(() => {
        const now = Date.now()

        const storageEventListener = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === generateAwvSummaryLocalStorageKey(memberId)) {
                if (e.newValue) {
                    const awvSummaryWrapper = JSON.parse(e.newValue)
                    if (awvSummaryWrapper.timestamp > now) {
                        dispatch(processNewOrUpdatedAwvSummary(awvSummaryWrapper.awvSummary))
                    }
                }
            }
        }

        window.addEventListener('storage', storageEventListener)

        return () => {
            window.removeEventListener('storage', storageEventListener)
        }
    }, [])

    useEffect(() => {
        hideMainWindowScrollbars(
            uploadResponseModalShown ||
                responseSavedMessageBoxShown ||
                isAttesting ||
                confirmEditShown ||
                confirmDeleteShown ||
                confirmUploadShown ||
                confirmSupportingDocumentDeletionShown ||
                fileTooLargeMessageBoxShown ||
                uploadSupportingDocumentModalShown ||
                showAWVHistory ||
                showFeedbackDialog ||
                isElectronicAwvSubmitByNonPCP
        )
    }, [
        uploadResponseModalShown,
        responseSavedMessageBoxShown,
        isAttesting,
        confirmEditShown,
        confirmDeleteShown,
        confirmUploadShown,
        confirmSupportingDocumentDeletionShown,
        fileTooLargeMessageBoxShown,
        uploadSupportingDocumentModalShown,
        showAWVHistory,
        showFeedbackDialog,
        isElectronicAwvSubmitByNonPCP
    ])

    const openInNewTab = (url: string) => {
        window.open(appPath(url), '_blank', 'noreferrer')
    }

    const handleViewElectronicAwvForm = () => {
        openInNewTab(
            `/members/viewElectronicAnnualWellnessVisitForm/${memberId}/${selectedYearProvider.npi}/${selectedYearProvider.year}`
        )
    }

    const editElectronicAwvForm = () => {
        openInNewTab(
            `/members/electronicAnnualWellnessVisitForm/${memberId}/${selectedYearProvider.npi}/${selectedYearProvider.year}`
        )
        setConfirmEditShown(false)
    }

    const handleEditElectronicAwvForm = () => {
        if (selectedAwvSummary?.$type === AWVType.Document && selectedAwvSummary.status !== 'Rejected') {
            if (selectedAwvSummary?.status === 'Submitted') {
                setConfirmEditMessage(
                    "A PDF version of the AWV form has been submitted for review. By editing the electronic AWV form, you'll unsubmit and discard the PDF version. Do you wish to proceed?"
                )
            } else {
                // selectedAwvSummary?.status === 'Saved'
                setConfirmEditMessage(
                    "A PDF version of the AWV form has been saved. By editing the electronic AWV form, you'll discard the PDF version. Do you wish to proceed?"
                )
            }
            setConfirmEditShown(true)
        } else if (selectedAwvSummary?.$type === AWVType.Electronic && selectedAwvSummary?.status === 'Submitted') {
            setConfirmEditMessage(
                `This electronic ${
                    selectedAwvSummary?.formTypeName ?? 'AWV'
                } form has been submitted for review. By editing it, you'll unsubmit it and will have to resubmit. Do you wish to proceed?`
            )
            setConfirmEditShown(true)
        } else {
            editElectronicAwvForm()
        }
    }

    const handleEditUnvalidatedElectronicAwvForm = () => {
        dispatch(
            setAwvValidationState({
                memberId,
                npi: selectedAwvSummary.providerNpi,
                year: selectedAwvSummary.year,
                validating: true
            })
        )
        editElectronicAwvForm()
        setUnvalidatedFormDialogShown(false)
    }

    const postRenderAwvElectronicFormPdf = usePostCustomEvent(
        pageTitle.PatientsAWV,
        customEvents.RenderAwvElectronicFormPdf
    )

    const handleRenderAwvElectronicFormPdf = () => {
        postRenderAwvElectronicFormPdf()
        setRenderingAwvElectronicFormPdf(true)
        dispatch(
            renderAwvElectronicFormPdf({
                memberId,
                npi: selectedAwvSummary.providerNpi,
                year: selectedAwvSummary.year
            })
        ).then(() => {
            setRenderingAwvElectronicFormPdf(false)
        })
    }

    const downloadSavedAWVPDF = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.DownloadSavedAWVPDF)
    const handleDownload = () => {
        downloadSavedAWVPDF()
        setDownloading(true)
        dispatch(
            downloadAwvFormDocumentResponse({
                memberId,
                npi: selectedAwvSummary.providerNpi,
                year: selectedAwvSummary.year
            })
        ).then(() => {
            setDownloading(false)
        })
    }
    const deleteAWVFormEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.DeleteAWVForm)
    const deleteAwvForm = () => {
        deleteAWVFormEvent()

        setConfirmDeleteShown(false)
        setDeleting(true)
        const args = { memberId, npi: selectedAwvSummary.providerNpi, year: selectedAwvSummary.year }
        dispatch(deleteAwvFormResponse(args)).then(() => {
            setDeleting(false)
            dispatch(deleteAwvSupportingDocuments(args))
        })
    }

    const provider: IProviderNpi = useAppSelector((state: { pulse8: Pulse8State }) =>
        state.pulse8.providerNpis.find((x) => x.npi === selectedAwvSummary?.providerNpi)
    )

    useEffect(() => {
        if (!provider) {
            dispatch(fetchProviderNpis())
        }
    }, [provider])

    // #region  Attestation
    const [isAttestDataReady, setIsAttestDataReady] = useState(false)
    const [attestation, setAttestation] = useState({
        text: '',
        templateId: 0,
        dateOfService: null
    })

    const handleAttested = () => {
        setSubmitting(true)
        submitAWVFormEvent()
        dispatch(
            submitSavedAwvFormResponse({
                memberId,
                npi: selectedAwvSummary.providerNpi,
                year: selectedAwvSummary.year,
                attestation: {
                    dateOfService: attestation.dateOfService,
                    attestationId: attestation.templateId,
                    text: attestation.text
                }
            })
        ).then(() => {
            setIsAttesting(false)
            setSubmitting(false)
        })
    }

    const isPCPCredentialAvailable =
        selectedAwvSummary?.$type === AWVType.Document ||
        (selectedAwvSummary?.$type === AWVType.Electronic && provider?.defaultCredential != null)

    const credentials = useMemberProfileLookupData()?.credentials
    useEffect(() => {
        if (isAttesting && isPCPCredentialAvailable && !isAttestDataReady) {
            dispatch(getAwvAttestationTemplate())
                .then(unwrapResult)
                .then((result) => {
                    const providerName = `${provider.firstName} ${provider.lastName}`
                    setAttestation((x) => ({
                        ...x,
                        text:
                            selectedAwvSummary?.$type === AWVType.Electronic
                                ? result.text
                                      .replace('{ProviderName}', providerName)
                                      .replace(
                                          '{DefaultCredential}',
                                          credentials.find((x) => x.id === provider.defaultCredential)?.name
                                      )
                                : undefined,
                        templateId: selectedAwvSummary?.$type === AWVType.Electronic ? result.attestationId : undefined
                    }))
                })
                .then(() => {
                    if (selectedAwvSummary?.$type === AWVType.Electronic) {
                        dispatch(
                            getAwvElectronicForm({
                                memberId,
                                npi: selectedAwvSummary.providerNpi,
                                year: selectedAwvSummary.year
                            })
                        )
                            .then(unwrapResult)
                            .then((data) => setAttestedDOS(data.response.dateOfService))
                            .then(() => setIsAttestDataReady(true))
                    } else {
                        setIsAttestDataReady(true)
                    }
                })
        }
    }, [isAttesting, isAttestDataReady, isPCPCredentialAvailable])

    const setAttestedDOS = (d: Date) => {
        setAttestation((x) => ({
            ...x,
            dateOfService: d
        }))
    }

    // #endregion  Attestation

    const handleSubmit = () => {
        if (selectedAwvSummary?.$type === AWVType.Electronic) {
            if (currentUser?.primaryRole !== ERoles.Provider) {
                setElectronicAwvSubmitByNonPCP(true)
            } else {
                if ((selectedAwvSummary as IAwvFormElectronicResponseSummary).validationErrorCount === 0) {
                    setIsAttesting(true)
                    setIsAttestDataReady(false)
                } else {
                    setUnvalidatedFormDialogShown(true)
                }
            }
        } else {
            setIsAttesting(true)
            setIsAttestDataReady(false)
        }
    }
    const submitAWVFormEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.SubmitAWVForm)

    const handleUpload = () => {
        const lowerCaseStatus = selectedAwvSummary?.status?.toLowerCase()
        if (selectedAwvSummary?.$type === AWVType.Electronic && selectedAwvSummary?.status !== 'Rejected') {
            setConfirmUploadMessage(
                `An electronic version of the ${
                    selectedAwvSummary?.formTypeName ?? 'AWV'
                } form has been ${lowerCaseStatus}. By saving a PDF version, you'll discard the electronic version. Do you wish to proceed?`
            )
            setConfirmUploadShown(true)
        } else if (selectedAwvSummary?.$type === AWVType.Document && selectedAwvSummary?.status === 'Submitted') {
            setConfirmUploadMessage(
                `A PDF version of the AWV form has been ${lowerCaseStatus}. By saving another PDF file, you'll replace the currently ${lowerCaseStatus} one. Do you wish to proceed?`
            )
            setConfirmUploadShown(true)
        } else {
            setUploadResponseModalShown(true)
        }
    }

    const confirmUpload = () => {
        setConfirmUploadShown(false)
        setUploadResponseModalShown(true)
    }
    const uploadAWVPDFEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.UploadAWVPDF)
    const performUpload = (file: any) => {
        if (file) {
            if (isFileOutsideMaxSizeLimit(file, false)) {
                setUploadResponseModalShown(false)
                setFileTooLargeMessageBoxShown(true)
            } else {
                uploadAWVPDFEvent()

                setUploading(true)
                dispatch(
                    saveAwvFormDocumentResponse({
                        memberId,
                        npi: selectedYearProvider.npi,
                        year: selectedYearProvider.year,
                        file: file
                    })
                ).then(() => {
                    setUploading(false)
                    setUploadResponseModalShown(false)
                    if (!window.localStorage.getItem('doNotShowResponseSavedMessageBox')) {
                        setResponseSavedMessageBoxShown(true)
                    }
                })
            }
        }
    }

    const closeResponseSavedMessageBox = (doNotShowAgain: boolean) => {
        if (doNotShowAgain) {
            window.localStorage.setItem('doNotShowResponseSavedMessageBox', 'true')
        }
        setResponseSavedMessageBoxShown(false)
    }

    const uploadSupportingDocument = () => {
        setUploadSupportingDocumentModalShown(true)
    }
    const uploadEncounterDocEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.UploadAWVSupportingDocument)
    const performSupportingDocumentUpload = (file: any) => {
        if (file) {
            if (isFileOutsideMaxSizeLimit(file, false)) {
                setUploadSupportingDocumentModalShown(false)
                setFileTooLargeMessageBoxShown(true)
            } else {
                uploadEncounterDocEvent()
                setSupportingDocumentUploading(true)
                dispatch(
                    uploadAwvSupportingDocument({
                        memberId,
                        npi: selectedAwvSummary.providerNpi,
                        year: selectedAwvSummary.year,
                        file: file
                    })
                ).then(() => {
                    setUploadSupportingDocumentModalShown(false)
                    setSupportingDocumentUploading(false)
                })
            }
        }
    }
    const downloadSupportingDocumentEvent = usePostCustomEvent(
        pageTitle.PatientsAWV,
        customEvents.DownloadAWVSupportingDocument
    )
    const downloadSupportingDocument = (document: IAwvSupportingDocument) => {
        downloadSupportingDocumentEvent()
        setSupportingDocumentDownloadingOrDeleting(true)
        dispatch(downloadAwvSupportingDocument({ awvSupportingDocumentId: document.id })).then(() => {
            setSupportingDocumentDownloadingOrDeleting(false)
        })
    }

    const handleDeleteSupportingDocument = (document: IAwvSupportingDocument) => {
        setSupportingDocumentToDelete(document)
        setConfirmSupportingDocumentDeletionShown(true)
    }

    const deleteEncounterDocEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.DeleteAWVSupportingDocument)
    const deleteSupportingDocument = () => {
        deleteEncounterDocEvent()
        setConfirmSupportingDocumentDeletionShown(false)
        setSupportingDocumentDownloadingOrDeleting(true)
        dispatch(deleteAwvSupportingDocument({ awvSupportingDocumentId: supportingDocumentToDelete.id })).then(() => {
            setSupportingDocumentDownloadingOrDeleting(false)
        })
    }

    const onViewAWVHistory = () => {
        setShowAWVHistory(true)
    }

    useEffect(() => {
        if (selectedAwvSummary?.awvId) {
            dispatch(
                hasNewAwvFeedback({
                    awvId: selectedAwvSummary.awvId
                })
            )
        }
    }, [selectedAwvSummary])

    const { awvFeedback, awvHasNewFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)
    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.ViewFeedback)
    const handleFeedback = () => {
        viewFeedbackEvent()

        setFeedbackLoading(true)
        setShowFeedbackDialog(true)
        dispatch(
            markAwvFeedbackRead({
                awvId: selectedAwvSummary.awvId
            })
        )
        dispatch(
            getAwvFeedback({
                awvId: selectedAwvSummary.awvId
            })
        ).then(() => {
            setFeedbackLoading(false)
        })
    }
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.SendFeedback)
    const handleSendFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addAwvFeedback({
                awvId: selectedAwvSummary.awvId,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }
    const changeYearEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.ChangeYear)
    const handleSelectedValueChanged = (value: number) => {
        setSelectedYearProviderIndex(value)
        changeYearEvent()
    }

    return (
        <>
            {initialContentLoaded && !memberProfileDetailsLoading && !deleting ? (
                yearProviderOptions?.length > 0 && selectedYearProviderIndex >= 0 ? (
                    <>
                        {isAttesting && !isPCPCredentialAvailable && (
                            <PCPCredentialModal
                                onCancel={() => {
                                    setIsAttesting(false)
                                }}
                                onError={() => {
                                    setIsAttesting(false)
                                }}
                            />
                        )}

                        {isAttesting && isPCPCredentialAvailable && isAttestDataReady && (
                            <AttestationModal
                                iniDate={attestation.dateOfService}
                                message={attestation.text}
                                setNewDate={setAttestedDOS}
                                onConfirm={handleAttested}
                                onCancel={() => {
                                    setIsAttesting(false)
                                }}
                            />
                        )}

                        {isElectronicAwvSubmitByNonPCP && (
                            <NonPCPSubmissionWarning
                                providerInfo={provider}
                                formType={selectedAwvSummary?.formTypeName}
                                onClose={() => setElectronicAwvSubmitByNonPCP(false)}
                            />
                        )}

                        {uploadResponseModalShown && (
                            <UploadFileModal
                                title="Upload AWV Form"
                                isUploading={uploading}
                                onUpload={performUpload}
                                onCancel={() => setUploadResponseModalShown(false)}
                            />
                        )}

                        {uploadSupportingDocumentModalShown && (
                            <UploadFileModal
                                title="Upload Encounter/Supporting Document"
                                isUploading={supportingDocumentUploading}
                                onUpload={performSupportingDocumentUpload}
                                onCancel={() => setUploadSupportingDocumentModalShown(false)}
                            />
                        )}

                        {responseSavedMessageBoxShown && (
                            <MessageBox
                                title="Upload AWV Form"
                                body="Your AWV form has been saved. Please submit it to make it eligible for review."
                                closeButtonLabel="OK"
                                closeButtonColor={UI_COLORS.light_blue2}
                                onClose={closeResponseSavedMessageBox}
                                showDoNotShowAgainCheckbox={true}
                            />
                        )}

                        {unvalidatedFormDialogShown && (
                            <UnvalidatedAwvSubmissionDialog
                                validationErrorCount={
                                    (selectedAwvSummary as IAwvFormElectronicResponseSummary).validationErrorCount
                                }
                                formType={selectedAwvSummary?.formTypeName}
                                onEdit={handleEditUnvalidatedElectronicAwvForm}
                                onCancel={() => setUnvalidatedFormDialogShown(false)}
                            />
                        )}

                        {confirmEditShown && (
                            <ConfirmationBox
                                title={selectedAwvSummary?.formTypeName ?? 'Annual Wellness Visit'}
                                body={confirmEditMessage}
                                confirmButtonLabel="Edit"
                                confirmButtonColor={UI_COLORS.light_blue2}
                                onConfirm={editElectronicAwvForm}
                                onCancel={() => setConfirmEditShown(false)}
                            />
                        )}

                        {confirmDeleteShown && (
                            <ConfirmationBox
                                title={selectedAwvSummary?.formTypeName ?? 'Annual Wellness Visit'}
                                body={`Are you sure you want to delete this ${
                                    selectedAwvSummary?.formTypeName ?? 'Annual Wellness Visit'
                                } form?`}
                                confirmButtonLabel="Delete"
                                confirmButtonColor={UI_COLORS.red}
                                onConfirm={deleteAwvForm}
                                onCancel={() => setConfirmDeleteShown(false)}
                            />
                        )}

                        {confirmUploadShown && (
                            <ConfirmationBox
                                title="Annual Wellness Visit"
                                body={confirmUploadMessage}
                                confirmButtonLabel="Upload"
                                confirmButtonColor={UI_COLORS.light_blue2}
                                onConfirm={confirmUpload}
                                onCancel={() => setConfirmUploadShown(false)}
                            />
                        )}

                        {fileTooLargeMessageBoxShown && (
                            <MessageBox
                                title={selectedAwvSummary?.formTypeName ?? 'Annual Wellness Visit'}
                                body="The file you are trying to upload is too large. Please upload a file up to 10MB in size."
                                closeButtonLabel="OK"
                                closeButtonColor={UI_COLORS.red}
                                onClose={() => setFileTooLargeMessageBoxShown(false)}
                            />
                        )}

                        {confirmSupportingDocumentDeletionShown && (
                            <ConfirmationBox
                                title={selectedAwvSummary?.formTypeName ?? 'Annual Wellness Visit'}
                                body={`Would you like to delete the supporting document ${supportingDocumentToDelete?.fileName}?`}
                                confirmButtonLabel="Delete"
                                confirmButtonColor={UI_COLORS.red}
                                onConfirm={deleteSupportingDocument}
                                onCancel={() => setConfirmSupportingDocumentDeletionShown(false)}
                            />
                        )}

                        {showAWVHistory && (
                            <AWVHistoryModal
                                memberId={memberId}
                                npi={selectedAwvSummary.providerNpi}
                                year={selectedAwvSummary.year}
                                onClose={function (): void {
                                    setShowAWVHistory(false)
                                }}
                            ></AWVHistoryModal>
                        )}

                        {showFeedbackDialog ? (
                            <FeedbackModalDialog
                                feedback={awvFeedback[selectedAwvSummary.awvId] ?? []}
                                isLoading={feedbackLoading}
                                isSending={feedbackSending}
                                onSend={handleSendFeedback}
                                onClose={() => setShowFeedbackDialog(false)}
                            />
                        ) : null}

                        <StyledAWVContainer>
                            <StyledDocumentsModalListContainer>
                                <StyledAWVFormTitle>
                                    <Typography type={TYPOGRAPHY_TYPES.h3}>Annual Wellness Visit Form</Typography>
                                    <StyledAWVInstructionWrap>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            Please complete the online web based AWV form OR the offline PDF. Only one
                                            or the other is required, <b>not both</b>.
                                        </Typography>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            For the web-based form, you are not required to submit an encounter with
                                            your form. The offline form requires submission of an encounter. Upload your
                                            offline form and encounter as one PDF.
                                        </Typography>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            For additional information, please refer to our{' '}
                                            <b>
                                                <a
                                                    href={process.env.PUBLIC_URL + '/docs/AwvFlyer.pdf'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Annual Wellness Visit
                                                </a>
                                            </b>{' '}
                                            overview. If you have any questions about the form, the review process, or
                                            the associated incentive, please feel free to contact us by clicking on the{' '}
                                            <i>Feedback</i> button located in the lower right hand side.
                                        </Typography>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            To participate in the incentive program, please complete this form at point
                                            of care, and use the following visit codes: G0402; G0438; G0439;
                                            99381-99387; 99391-99397.
                                        </Typography>
                                        <Typography type={TYPOGRAPHY_TYPES.p} style={{ marginTop: '40px' }}>
                                            <b>Alternatively</b>, you can complete the Patient Assessment online
                                            web-based form. This form does not require the submission of an encounter.
                                            To participate in the incentive program, please complete this form at point
                                            of care, and use the following visit codes: 99381-99387; 99391-99397.
                                        </Typography>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            <b>Note:</b> Only an AWV OR Patient Assessment form can be submitted,{' '}
                                            <b>not both</b>, for a given patient for a given year.
                                        </Typography>
                                    </StyledAWVInstructionWrap>
                                </StyledAWVFormTitle>
                                <StyledDropdownContainer>
                                    <>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>
                                            {isAdmin ? 'Year/Provider:' : 'Year:'}
                                        </Typography>
                                        <StyledCalendarIcon image={smallCalendar} />
                                        <DropDownList
                                            options={yearProviderOptions}
                                            isDisabled={submitting || isAttesting}
                                            selectedValue={selectedYearProviderIndex}
                                            setSelectedValue={(value) => handleSelectedValueChanged(value)}
                                            styleParams={
                                                isAdmin
                                                    ? adminCalendarDropDownListStyleParams
                                                    : calendarDropDownListStyleParams
                                            }
                                        />
                                    </>
                                </StyledDropdownContainer>
                            </StyledDocumentsModalListContainer>
                            <StyledAWVInfoContainer>
                                {selectedAwvSummary &&
                                    (selectedAwvSummary.$type === AWVType.Document ||
                                        selectedAwvSummary.$type === AWVType.Electronic) && (
                                        <>
                                            <fieldset disabled={false}>
                                                <StyledAWVInfoInputWrap>
                                                    <StyledAWVInfoInputContainer>
                                                        <label>Form Type</label>
                                                        <input name="type" value={typeLabel} disabled={true} />
                                                    </StyledAWVInfoInputContainer>
                                                    <StyledAWVInfoInputWithRightButtonContainer>
                                                        <label>Status</label>
                                                        <StyledStatusBox>
                                                            <CustomSvgIcon
                                                                iconSet={{
                                                                    icon: getStatusIcon(selectedAwvSummary.status)
                                                                }}
                                                                svg
                                                            ></CustomSvgIcon>
                                                            <input
                                                                name="effectiveDate"
                                                                value={effectiveDateLabel + ' ' + effectiveDateValue}
                                                                disabled={true}
                                                            />
                                                            <Tooltip title="AWV Response History">
                                                                <IconButton size="small" onClick={onViewAWVHistory}>
                                                                    <AccessTimeIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </StyledStatusBox>
                                                    </StyledAWVInfoInputWithRightButtonContainer>
                                                </StyledAWVInfoInputWrap>

                                                <StyledAWVInfoActionsContainer>
                                                    <StyledSmallDetailsButton
                                                        onClick={handleSubmit}
                                                        disabled={!canSubmitSaved || submitting || isAttesting}
                                                        color={
                                                            canSubmitSaved && !(submitting || isAttesting)
                                                                ? UI_COLORS.light_green
                                                                : null
                                                        }
                                                    >
                                                        Submit
                                                    </StyledSmallDetailsButton>
                                                    {selectedAwvSummary.$type === AWVType.Document && (
                                                        <>
                                                            <StyledMediumDetailsButton
                                                                onClick={handleUpload}
                                                                disabled={!canSaveOrSubmit || submitting || isAttesting}
                                                            >
                                                                Upload&nbsp;
                                                                <CustomSvgIcon iconSet={{ icon: uploadIcon }} svg />
                                                            </StyledMediumDetailsButton>
                                                            <StyledMediumDetailsButton
                                                                disabled={downloading || submitting || isAttesting}
                                                                onClick={handleDownload}
                                                                noFill={true}
                                                                stroke={true}
                                                            >
                                                                Download{' '}
                                                                <CustomSvgIcon
                                                                    iconSet={{ icon: downloadIcon }}
                                                                    svg
                                                                ></CustomSvgIcon>
                                                            </StyledMediumDetailsButton>
                                                        </>
                                                    )}
                                                    {selectedAwvSummary.$type === AWVType.Electronic && (
                                                        <>
                                                            <StyledSmallDetailsButton
                                                                onClick={handleViewElectronicAwvForm}
                                                                disabled={submitting || isAttesting}
                                                            >
                                                                View{' '}
                                                                <CustomSvgIcon
                                                                    iconSet={{
                                                                        icon: openInNew
                                                                    }}
                                                                    svg
                                                                ></CustomSvgIcon>
                                                            </StyledSmallDetailsButton>
                                                            <StyledSmallDetailsButton
                                                                disabled={!canSaveOrSubmit || submitting || isAttesting}
                                                                onClick={handleEditElectronicAwvForm}
                                                            >
                                                                Edit{' '}
                                                                <CustomSvgIcon
                                                                    iconSet={{
                                                                        icon: openInNew
                                                                    }}
                                                                    svg
                                                                ></CustomSvgIcon>
                                                            </StyledSmallDetailsButton>
                                                            <StyledSmallDetailsButton
                                                                disabled={
                                                                    renderingAwvElectronicFormPdf ||
                                                                    submitting ||
                                                                    isAttesting
                                                                }
                                                                onClick={handleRenderAwvElectronicFormPdf}
                                                                noFill={true}
                                                                stroke={true}
                                                            >
                                                                PDF{' '}
                                                                <CustomSvgIcon
                                                                    iconSet={{ icon: downloadIcon }}
                                                                    svg
                                                                ></CustomSvgIcon>
                                                            </StyledSmallDetailsButton>
                                                        </>
                                                    )}

                                                    <StyledActionButton
                                                        disabled={!canDelete || submitting || isAttesting}
                                                        onClick={() => setConfirmDeleteShown(true)}
                                                        color={
                                                            canDelete && !(submitting || isAttesting)
                                                                ? UI_COLORS.red
                                                                : null
                                                        }
                                                    >
                                                        Delete
                                                    </StyledActionButton>
                                                </StyledAWVInfoActionsContainer>
                                            </fieldset>
                                            <SupportingDocumentsTable
                                                awvSupportingDocuments={
                                                    awvSupportingDocuments[
                                                        getAwvKey(
                                                            memberId,
                                                            selectedAwvSummary.providerNpi,
                                                            selectedAwvSummary.year
                                                        )
                                                    ]
                                                }
                                                isLoading={supportingDocumentsLoading}
                                                isBusy={
                                                    supportingDocumentUploading ||
                                                    supportingDocumentDownloading ||
                                                    submitting ||
                                                    isAttesting
                                                }
                                                canUploadAndDelete={canSaveOrSubmit}
                                                onUpload={uploadSupportingDocument}
                                                onDownload={downloadSupportingDocument}
                                                onDelete={handleDeleteSupportingDocument}
                                            />
                                        </>
                                    )}
                            </StyledAWVInfoContainer>

                            {selectedAwvSummary && canSaveOrSubmit && selectedAwvSummary.$type !== AWVType.Scheduled ? (
                                <Divider>OR</Divider>
                            ) : (
                                <></>
                            )}

                            {(!selectedAwvSummary || selectedAwvSummary.$type !== AWVType.Electronic) &&
                            canSaveOrSubmit ? (
                                <>
                                    <StyledAWVTitleDetailContainer>
                                        <Typography type={TYPOGRAPHY_TYPES.h2}>Web Based Method</Typography>
                                        <StyledAWVInstructionWrap>
                                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                                Complete and submit an AWV via Web Based form.
                                            </Typography>
                                        </StyledAWVInstructionWrap>
                                    </StyledAWVTitleDetailContainer>
                                    <StyledAWVInfoContainer>
                                        {!selectedAwvSummary ||
                                        selectedAwvSummary?.$type === AWVType.Document ||
                                        selectedAwvSummary?.$type === AWVType.Scheduled ? (
                                            <StyledNoLeftMarginSmallDetailsButton
                                                disabled={!canSaveOrSubmit || submitting}
                                                onClick={handleEditElectronicAwvForm}
                                            >
                                                Edit <CustomSvgIcon iconSet={{ icon: openInNew }} svg></CustomSvgIcon>
                                            </StyledNoLeftMarginSmallDetailsButton>
                                        ) : (
                                            ''
                                        )}
                                    </StyledAWVInfoContainer>
                                </>
                            ) : null}

                            {(!selectedAwvSummary && canSaveOrSubmit) ||
                            selectedAwvSummary?.$type === AWVType.Scheduled ? (
                                <Divider>OR</Divider>
                            ) : (
                                <></>
                            )}

                            {(!selectedAwvSummary || selectedAwvSummary.$type !== AWVType.Document) &&
                            canSaveOrSubmit ? (
                                <>
                                    <StyledAWVTitleDetailContainer>
                                        <Typography type={TYPOGRAPHY_TYPES.h2}>Offline PDF Method</Typography>
                                        <StyledAWVLongInstructionWrap>
                                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                                Download the AWV template and upload the completed file. (Note: It can
                                                be handwritten or in digital format)
                                            </Typography>
                                        </StyledAWVLongInstructionWrap>
                                    </StyledAWVTitleDetailContainer>
                                    <FileDownloader
                                        memberId={memberId}
                                        npi={selectedYearProvider.npi}
                                        isBusy={submitting || isAttesting}
                                    />
                                    <Typography
                                        type={TYPOGRAPHY_TYPES.h4}
                                        style={{ marginTop: '20px', marginBottom: '15px' }}
                                    >
                                        <b>
                                            <i>Upload your completed Annual Wellness Visit Form below</i>
                                        </b>
                                    </Typography>
                                    <StyledDownloadButtonWrap style={{ marginBottom: '25px' }}>
                                        <StyledDetailsButton
                                            onClick={handleUpload}
                                            disabled={submitting || isAttesting}
                                        >
                                            Upload&nbsp;
                                            <CustomSvgIcon iconSet={{ icon: uploadIcon }} svg />
                                        </StyledDetailsButton>
                                    </StyledDownloadButtonWrap>
                                </>
                            ) : null}
                            {selectedAwvSummary?.awvId && (
                                <AWVFeedback
                                    openFeedback={handleFeedback}
                                    hasNewFeedback={
                                        awvHasNewFeedback[selectedAwvSummary.awvId]?.hasNewFeedback ?? false
                                    }
                                    feedbackCount={awvHasNewFeedback[selectedAwvSummary.awvId]?.newFeedbackCount ?? 0}
                                />
                            )}
                        </StyledAWVContainer>
                    </>
                ) : (
                    <StyledAWVContainer>
                        <Typography type={TYPOGRAPHY_TYPES.h1}>Annual Wellness Visit</Typography>
                        <Typography type={TYPOGRAPHY_TYPES.h2}>
                            No Annual Wellness Visit have been submitted or scheduled.
                        </Typography>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            Once an Annual Wellness Visit is submitted or scheduled, it will show up here.
                        </Typography>
                    </StyledAWVContainer>
                )
            ) : !isBadData ? (
                <Spinner />
            ) : null}
        </>
    )
}

export default AWV
