import { useEffect } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

import AuthenticatedApp from 'AuthenticatedApp'
import UnauthenticatedApp from 'UnauthenticatedApp'

import { AppInsightsContext, useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from 'services/appInsightService'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import { StyledErrorButton, StyledErrorMessage, StyledErrorMessageContainer, StyledSubErrorMessage } from 'index.styles'
import { dispatch, useSelector } from 'redux/store'
import { resetUser } from 'redux/slices/user'
import { resetIncentives } from 'redux/slices/incentive'
import ErrorBox from 'components/ErrorBox'
import { clearError, resetSystem, SystemState } from 'redux/slices/system'
import Spinner from 'components/Spinner'

export interface IErrorBoundaryError {
    message: string
    stack?: string
}

// deconstruct FallbackProps in function parameters
const ErrorFallbackAuthenticated = ({ error, resetErrorBoundary }: FallbackProps) => {
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        appInsights.trackEvent({
            name: 'GlobalErrorCatchAll',
            properties: { message: error.message, stack: error.stack }
        })
    }, [])

    return (
        <StyledErrorMessageContainer role="alert">
            <StyledErrorMessage>We are sorry, something went wrong!</StyledErrorMessage>
            <StyledSubErrorMessage>
                The HCP Service is having difficulties right now. Click <b>Try again</b>, and if that does not work,
                please contact HCP Technical Support at (800) 877-7587
            </StyledSubErrorMessage>
            <pre>Internal Error: {error?.message}</pre>
            <StyledErrorButton
                onClick={() => {
                    resetErrorBoundary() // force a re-render of the component tree
                }}
            >
                Try again
            </StyledErrorButton>
        </StyledErrorMessageContainer>
    )
}

const ErrorFallbackUnauthenticated = ({ error, resetErrorBoundary }: FallbackProps) => {
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        appInsights.trackEvent({
            name: 'GlobalErrorCatchAllUnauthentcated',
            properties: { message: error.message, stack: error.stack }
        })
    }, [])

    return <></>
}

const App = () => {
    const { windowClosing, error } = useSelector((state: { system: SystemState }) => state.system)

    const dismissError = () => {
        dispatch(clearError())
    }

    const handleReset = () => {
        dispatch(resetSystem())
        dispatch(resetUser())
        dispatch(resetIncentives())
    }

    return (
        <>
            {!windowClosing ? (
                <>
                    {error && <ErrorBox error={error} onClose={dismissError} />}

                    <AuthenticatedTemplate>
                        <AppInsightsContext.Provider value={reactPlugin}>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallbackAuthenticated}
                                onReset={() => {
                                    // reset the state of your app so the error doesn't happen again
                                    handleReset()
                                }}
                            >
                                <AuthenticatedApp />
                            </ErrorBoundary>
                        </AppInsightsContext.Provider>
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <AppInsightsContext.Provider value={reactPlugin}>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallbackUnauthenticated}
                                onReset={() => {
                                    // reset the state of your app so the error doesn't happen again
                                    handleReset()
                                }}
                            >
                                <UnauthenticatedApp />
                            </ErrorBoundary>
                        </AppInsightsContext.Provider>
                    </UnauthenticatedTemplate>
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default App
